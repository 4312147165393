<template>
  <div>
<!--    <Main>-->
<!--      <a-row justify="center" :gutter="25">-->
<!--        <a-col :xxl="8" :lg="24" :xs="24">-->
<!--          <Suspense>-->
<!--            <template #default>-->
<!--              <Overview />-->
<!--            </template>-->
<!--            <template #fallback>-->
<!--              <cds-cards headless>-->
<!--                <a-skeleton active />-->
<!--              </cds-cards>-->
<!--            </template>-->
<!--          </Suspense>-->
<!--        </a-col>-->
<!--        <a-col :xxl="16" :xs="24">-->
<!--          <Suspense>-->
<!--            <template #default>-->
<!--              <LineChartOverview />-->
<!--            </template>-->
<!--            <template #fallback>-->
<!--              <cds-cards headless>-->
<!--                <a-skeleton active />-->
<!--              </cds-cards>-->
<!--            </template>-->
<!--          </Suspense>-->
<!--        </a-col>-->
<!--        <a-col :xxl="8" :xs="24">-->
<!--          <Suspense>-->
<!--            <template #default>-->
<!--              <InteractiveOverview />-->
<!--            </template>-->
<!--            <template #fallback>-->
<!--              <cds-cards headless>-->
<!--                <a-skeleton active />-->
<!--              </cds-cards>-->
<!--            </template>-->
<!--          </Suspense>-->
<!--        </a-col>-->
<!--        <a-col :xxl="16" :xs="24">-->
<!--          <Suspense>-->
<!--            <template #default>-->
<!--              <Register />-->
<!--            </template>-->
<!--            <template #fallback>-->
<!--              <cds-cards headless>-->
<!--                <a-skeleton active />-->
<!--              </cds-cards>-->
<!--            </template>-->
<!--          </Suspense>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--    </Main>-->
  </div>
</template>

<script>
// import { defineAsyncComponent } from 'vue';
// import { Main } from '../styled';

// const Overview = defineAsyncComponent(() => import('./overview/Overview'));
// const LineChartOverview = defineAsyncComponent(() => import('./overview/LineChartOverview'));
// const Register = defineAsyncComponent(() => import('./overview/Register'));
// const InteractiveOverview = defineAsyncComponent(() => import('./overview/InteractiveOverview'));

export default {
  name: 'SocialMedia',
  components: {
    // Overview,
    // LineChartOverview,
    // Register,
    // InteractiveOverview,
    // Main
  },
  async setup() {},
};
</script>
